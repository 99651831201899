// extracted by mini-css-extract-plugin
export var blink = "Feed-module--blink--b8562";
export var btnLink = "Feed-module--btn-link--442d2";
export var btnPost = "Feed-module--btn-post--ec3ca";
export var category = "Feed-module--category--59f58";
export var description = "Feed-module--description--57348";
export var divider = "Feed-module--divider--81a18";
export var e1 = "Feed-module--e1--3d108";
export var e1_1 = "Feed-module--e1_1--de58a";
export var e2 = "Feed-module--e2--c0da3";
export var e2_2 = "Feed-module--e2_2--65d06";
export var emoji = "Feed-module--emoji--dc37d";
export var emojiR1 = "Feed-module--emojiR1--05bff";
export var emojiTxt = "Feed-module--emojiTxt--2f890";
export var emptyWrap = "Feed-module--emptyWrap--49d46";
export var emptyWrap1 = "Feed-module--emptyWrap1--dd735";
export var fadeaway = "Feed-module--fadeaway--80a03";
export var fadein = "Feed-module--fadein--bfa39";
export var feed = "Feed-module--feed--a6204";
export var item = "Feed-module--item--c7a63";
export var justfade = "Feed-module--justfade--b7a74";
export var justpushup = "Feed-module--justpushup--14fd8";
export var link = "Feed-module--link--6123b";
export var meta = "Feed-module--meta--250c2";
export var more = "Feed-module--more--51a4e";
export var pushup = "Feed-module--pushup--04dc2";
export var time = "Feed-module--time--72864";
export var title = "Feed-module--title--f252f";
export var typing = "Feed-module--typing--0f303";
export var widthgrow = "Feed-module--widthgrow--68a26";
export var wrapper = "Feed-module--wrapper--f0260";